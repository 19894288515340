import revive_payload_client_4sVQNw7RlN from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/vupa/Documents/mirascan/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vupa/Documents/mirascan/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vupa/Documents/mirascan/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/home/vupa/Documents/mirascan/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import antd_mx6PWiyNBy from "/home/vupa/Documents/mirascan/plugins/antd.ts";
import gtm_cXsUZobmSF from "/home/vupa/Documents/mirascan/plugins/gtm.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  antd_mx6PWiyNBy,
  gtm_cXsUZobmSF
]