import { default as bang_45giaWqRRC23xtBMeta } from "/home/vupa/Documents/mirascan/pages/bang-gia.vue?macro=true";
import { default as forgot_45passwordeYdxgbPvyfMeta } from "/home/vupa/Documents/mirascan/pages/forgot-password.vue?macro=true";
import { default as giai_45phap63Kartkp8uMeta } from "/home/vupa/Documents/mirascan/pages/giai-phap.vue?macro=true";
import { default as ho_45troIq8JChVMxMMeta } from "/home/vupa/Documents/mirascan/pages/ho-tro.vue?macro=true";
import { default as indexiJHCkZqawjMeta } from "/home/vupa/Documents/mirascan/pages/index.vue?macro=true";
import { default as loginSbyRri8SG4Meta } from "/home/vupa/Documents/mirascan/pages/login.vue?macro=true";
import { default as not_45loginViRGZ9OyWiMeta } from "/home/vupa/Documents/mirascan/pages/not-login.vue?macro=true";
import { default as qrcode_45bao_45hanhpllfrbR1YYMeta } from "/home/vupa/Documents/mirascan/pages/qrcode-bao-hanh.vue?macro=true";
import { default as qrcode_45chong_45giaU0CRE3APB8Meta } from "/home/vupa/Documents/mirascan/pages/qrcode-chong-gia.vue?macro=true";
import { default as qrcode_45loyaltyya8djCYfkaMeta } from "/home/vupa/Documents/mirascan/pages/qrcode-loyalty.vue?macro=true";
import { default as qrcode_45marketing3049yq4qjxMeta } from "/home/vupa/Documents/mirascan/pages/qrcode-marketing.vue?macro=true";
import { default as registerKSMqt703GlMeta } from "/home/vupa/Documents/mirascan/pages/register.vue?macro=true";
import { default as tao_45ma_45qrXxdvQKhMizMeta } from "/home/vupa/Documents/mirascan/pages/tao-ma-qr.vue?macro=true";
import { default as ve_45qrxEI1H01xFULMeta } from "/home/vupa/Documents/mirascan/pages/ve-qrx.vue?macro=true";
import { default as zalo_45mini_45app9yQU1A9PEbMeta } from "/home/vupa/Documents/mirascan/pages/zalo-mini-app.vue?macro=true";
export default [
  {
    name: "bang-gia",
    path: "/bang-gia",
    component: () => import("/home/vupa/Documents/mirascan/pages/bang-gia.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/vupa/Documents/mirascan/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap",
    path: "/giai-phap",
    component: () => import("/home/vupa/Documents/mirascan/pages/giai-phap.vue").then(m => m.default || m)
  },
  {
    name: "ho-tro",
    path: "/ho-tro",
    component: () => import("/home/vupa/Documents/mirascan/pages/ho-tro.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vupa/Documents/mirascan/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/vupa/Documents/mirascan/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "not-login",
    path: "/not-login",
    component: () => import("/home/vupa/Documents/mirascan/pages/not-login.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-bao-hanh",
    path: "/qrcode-bao-hanh",
    component: () => import("/home/vupa/Documents/mirascan/pages/qrcode-bao-hanh.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-chong-gia",
    path: "/qrcode-chong-gia",
    component: () => import("/home/vupa/Documents/mirascan/pages/qrcode-chong-gia.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-loyalty",
    path: "/qrcode-loyalty",
    component: () => import("/home/vupa/Documents/mirascan/pages/qrcode-loyalty.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-marketing",
    path: "/qrcode-marketing",
    component: () => import("/home/vupa/Documents/mirascan/pages/qrcode-marketing.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/vupa/Documents/mirascan/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "tao-ma-qr",
    path: "/tao-ma-qr",
    component: () => import("/home/vupa/Documents/mirascan/pages/tao-ma-qr.vue").then(m => m.default || m)
  },
  {
    name: "ve-qrx",
    path: "/ve-qrx",
    component: () => import("/home/vupa/Documents/mirascan/pages/ve-qrx.vue").then(m => m.default || m)
  },
  {
    name: "zalo-mini-app",
    path: "/zalo-mini-app",
    component: () => import("/home/vupa/Documents/mirascan/pages/zalo-mini-app.vue").then(m => m.default || m)
  }
]