<script setup></script>

<template>
  <div class="fixed bottom-[20px] right-[24px] z-10 max-lg:bottom-[170px]">
    <nuxt-link
      href="tel:0969059786"
      class="w-[60px] h-[60px] rounded-[50%] bg-[#ed7f22] flex justify-center items-center text-white hover:opacity-95 transition-all duration-300"
    >
      <nuxt-picture
        src="/images/icons/fi-sr-phone-call-1.png"
        :imgAttrs="{ alt: 'phone icon', width: 30, height: 30 }"
      />
    </nuxt-link>
  </div>
</template>
